<template>
  <div class="transfer-details">
    <h1 class="headline-small mb-s24">
      {{ $t("wallet.modal.wire_transfer_details.title") }}
    </h1>

    <div
      v-for="(detail, index) in details"
      :key="index"
      class="transfer-details__line"
      data-testid="details-line"
    >
      <span class="body-text-x-large mr-s24">{{ detail.label }}</span>
      <div class="flex items-start">
        <!-- Render multiple lines properly if value is array -->
        <div v-if="Array.isArray(detail.value)">
          <div v-for="(detail, index) in detail.value" :key="index" class="transfer-details__value subheadline-small">
            <SkeletonLoading v-if="isLoading" borderRadius="10px" height="18px" width="140px" class="mt-s2"/>
            <span v-else>{{ detail }}</span>
          </div>
        </div>

        <!-- Otherwise, render single line as usual -->
        <template v-else>
          <SkeletonLoading v-if="isLoading" borderRadius="10px" height="18px" width="190px"/>
          <div v-else class="transfer-details__value subheadline-small whitespace-nowrap overflow-hidden overflow-ellipsis">
            {{ detail.value }}
          </div>
        </template>

        <!-- Copy to clipboard -->
        <IconTransfer class="transfer-details__icon" @click="clipboardCopy(detail.value)"/>
      </div>
    </div>

    <ButtonV2
      @onClick="hideModal"
      class="mt-s24"
      :label="$tc('wallet.modal.wire_transfer_details.close')"
      testId="btn-close"
      size="medium"
      version="primary"
      wide
    />
  </div>
</template>

<script>
import IconTransfer from '@/assets/icons/transfer.svg';
import ButtonV2 from '@/stories/misc/ButtonV2.vue';
import { mapActions } from 'vuex';

export default {
  name: 'WireTransferDetails',
  components: {
    ButtonV2,
    IconTransfer,
  },

  async mounted() {
    await this.loadData();
    await this.$store.dispatch('events/track', {
      event: 'WIRE_INFORMATION_DETAILS_VIEWED',
    });
  },

  data() {
    return {
      isLoading: true,
      details: [
        {
          value: '',
          label: this.$t('wallet.modal.wire_transfer_details.memo'),
        },
        {
          value: '',
          label: `${this.$t('wallet.modal.wire_transfer_details.account')} #`,
        },
        {
          value: '',
          label: this.$t('wallet.modal.wire_transfer_details.bank_name'),
        },
        {
          value: '',
          label: `${this.$t('wallet.modal.wire_transfer_details.routing')} #`,
        },
        {
          value: new Array(3).fill(''),
          label: this.$t('wallet.modal.wire_transfer_details.bank_address'),
        },
        {
          value: new Array(2).fill(''),
          label: this.$t('wallet.modal.wire_transfer_details.beneficiary_address'),
        },
        {
          value: new Array(2).fill(''),
          label: this.$t('wallet.modal.wire_transfer_details.credit_to'),
        },
      ],
    };
  },

  methods: {
    ...mapActions('wallet', ['getWireInstructions']),

    async loadData() {
      const response = await this.getWireInstructions({ wireId: this.wireId });
      if (response) {
        this.details[0].value = this.lodashGet(response, 'tracking_reference', '');
        this.details[1].value = this.lodashGet(response, 'beneficiary_bank.accountNumber', '');
        this.details[2].value = this.lodashGet(response, 'beneficiary_bank.name', '');
        this.details[3].value = this.lodashGet(response, 'beneficiary_bank.routingNumber', '');

        const bankAddress1 = this.lodashGet(response, 'beneficiary_bank.address', '');
        const bankAddress2 = this.lodashGet(response, 'beneficiary_bank.city', '');
        const bankAddress3 = this.lodashGet(response, 'beneficiary_bank.postalCode', '');
        this.details[4].value = [bankAddress1, bankAddress2, bankAddress3];

        const beneficiaryAddress1 = this.lodashGet(response, 'beneficiary.address1', '');
        const beneficiaryAddress2 = this.lodashGet(response, 'beneficiary.address2', '');
        this.details[5].value = [beneficiaryAddress1, beneficiaryAddress2];

        const beneficiaryName1 = this.lodashGet(response, 'beneficiary.name', '');
        const beneficiaryName2 = ''; // this is just so it behaves as a two-lines attribute for skeleton purposes
        this.details[6].value = [beneficiaryName1, beneficiaryName2];
      } 
      this.isLoading = false;
    },
  },

  props: {
    wireId: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
  .transfer-details {
    @apply flex flex-col max-w-sm;
    width: 408px;
    max-width: 100%;
  }

  .transfer-details__line {
    @apply flex justify-between items-start py-s16 border-b;
  }

  .transfer-details__value {
    @apply text-right;
    max-width: 190px;
  }

  .transfer-details__icon {
    @apply ml-s16 text-text-active-2 fill-current cursor-pointer;
    width: 16px;
    height: 20px;
  }
</style>
